import { useState, useEffect, useRef } from 'react';
import GridLayout from 'react-grid-layout';
import { makeStyles, withStyles } from '@mui/styles';
import { Button, Checkbox, IconButton } from '@mui/material';
import { checkboxClasses } from '@mui/material/Checkbox';
import clsx from 'clsx';
import Imgix from 'react-imgix';
import './PhotoGrid.css';
import sharedStyles from 'styles/shared';
import { cdnUri } from 'common/helpers/content';
import TrashIcon from 'components/v2/Icons/Trash';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import { useDimensions } from 'hooks/useDimensions';
import { PhotoModal } from './PhotoModal';

const useStyles = makeStyles(theme => ({
  photoOverlay: {
    backgroundColor: theme.palette.stoneGrey.black80,
    opacity: 0,
    '&:hover': {
      opacity: 0.8,
    },
  },
  visbilityOverlay: {
    backgroundColor: theme.palette.stoneGrey.white80,
    opacity: 0.8,
  },
  imageOverlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0,0,0,.6)',
    color: '#fff',
    fontSize: 12,
    padding: theme.spacing(0.5),
    width: '100%',
    '& .muiIconContainer': {
      flexShrink: 0,
      '& button': {
        minWidth: 'unset',
      },
      '& svg': {
        width: 18,
        height: 'auto',
      },
    },
  },
  imageOverlayTop: {
    top: 0,
    left: 0,
  },
  imageOverlayBottom: {
    bottom: 0,
    left: 0,
    justifyContent: 'flex-end',
  },
  fileName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  visibilityToggle: {
    backgroundColor: theme.palette.stoneGrey.black80,
    top: 0,
    right: 0,
    padding: theme.spacing(0.5),
    height: '25px',
    width: '25px',
  },
  noPhotosPanel: {
    backgroundColor: theme.palette.stoneGrey.base,
    padding: theme.spacing(4),
    width: 'fit-content',
  },
  dragIconContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.stoneGrey.black80,
    opacity: 0,
    '&:hover': {
      opacity: 0.8,
    },
  },
  dragIcon: {
    height: '25px',
    width: '25px',
  },
}));

const onDownloadClick = async mediaItem => {
  const response = await fetch(cdnUri(mediaItem));
  const responseBlob = await response.blob();
  const blob = new Blob([responseBlob], { type: mediaItem.mimeType });
  const d = document.createElement('a');
  d.setAttribute('href', URL.createObjectURL(blob));
  d.setAttribute('download', mediaItem.userFilename);
  document.body.appendChild(d);
  d.click();
  document.body.removeChild(d);
};

export default function PhotoGrid({ mediaContent = [], handleChange, handleToggle, handleDelete }) {
  const s = sharedStyles();
  const classes = useStyles();

  const gridRef = useRef(null);
  const { width } = useDimensions(gridRef);

  const [layout, setLayout] = useState([]);
  const [preview, setPreview] = useState(null);

  // Set the initial layout when the component mounts
  useEffect(() => {
    if (mediaContent.length === 0) return;
    setLayout(
      mediaContent.map((el, i) => {
        const x = i % 4;
        const y = Math.floor(i / 4);
        return { i: el.id, x, y, w: 1, h: 4, maxW: 1, maxH: 4, ...el };
      }),
    );
  }, [mediaContent]);

  const handleLayoutChange = newLayoutRaw => {
    // translate x/y coordinate system to presentation order
    const sortedEls = [...newLayoutRaw].sort((a, b) => {
      const vertDiff = a.y - b.y;
      if (vertDiff !== 0) return vertDiff;
      return a.x - b.x;
    });
    const mediaContents = sortedEls.map(el => {
      const media = mediaContent.find(m => m.id === el.i);
      return { ...media };
    });
    const newLayout = sortedEls.map((el, idx) => ({
      ...el,
      ...mediaContents[idx],
      x: idx % 4,
      y: Math.floor(idx / 4),
    }));
    // update the db to persist correct order
    const hasChanged = newLayout.some((el, idx) => {
      const oldEl = layout[idx];
      return el.id !== oldEl.id;
    });
    if (hasChanged) {
      handleChange([...mediaContents]);
    }
    // update the layout locally on the page
    setLayout(newLayout);
  };

  if (mediaContent.length === 0) {
    return <div className={classes.noPhotosPanel}>Currently no photos have been included. Please add a photo.</div>;
  }

  return (
    <>
      <div ref={gridRef}>
        <GridLayout
          compactType="vertical"
          isResizable={false}
          className="layout"
          layout={layout}
          cols={4}
          rowHeight={40}
          width={width}
          onLayoutChange={handleLayoutChange}
        >
          {layout.map(el => {
            return (
              <div key={el.id} className={clsx(s.noOverflow, s.fullHeight, s.fullWidth)}>
                <div className={clsx(classes.imageContainer, s.relative, s.fullHeight)}>
                  {/* TODO - not all images are in the same aspect ratio, cauing the footer to be slightly elevated */}
                  <Imgix className={s.fullWidth} src={cdnUri(el)} width={450} />
                  <div className={clsx(s.absolute, classes.imageOverlay, classes.imageOverlayTop)}>
                    <span className="muiIconContainer">
                      <WhiteIconButton component={Button} size="small" onClick={() => onDownloadClick(el)}>
                        <CloudDownloadIcon />
                      </WhiteIconButton>
                    </span>
                    <span className={classes.fileName}>{el.userFilename}</span>
                    <span className="muiIconContainer">
                      <WhiteIconButton component={Button} size="small" onClick={() => setPreview(el)}>
                        <FitScreenIcon />
                      </WhiteIconButton>
                    </span>
                  </div>
                  <div className={clsx(s.absolute, classes.imageOverlay, classes.imageOverlayBottom)}>
                    <span>
                      <WhiteCheckbox size="small" name={el.id} onChange={handleToggle} value={el.id} />
                      <WhiteIconButton size="small" onClick={() => handleDelete(el)}>
                        <TrashIcon />
                      </WhiteIconButton>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </GridLayout>
      </div>
      {Boolean(preview) && (
        <PhotoModal
          open={true}
          onClose={() => setPreview(null)}
          mediaIndex={mediaContent.indexOf(mediaContent.find(el => el.s3Key === preview?.s3Key))}
          mediaContent={mediaContent}
        />
      )}
    </>
  );
}
const WhiteIconButton = withStyles({
  root: {
    color: 'white',
    '&:hover': 'rgba(255,255,255,0.7)',
  },
})(IconButton);

const WhiteCheckbox = withStyles({
  root: {
    [`&, &.${checkboxClasses.checked}`]: {
      color: 'white',
    },
  },
})(Checkbox);
