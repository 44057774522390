import { Controller } from 'react-hook-form';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  colorContainer: {
    padding: 4,
    borderRadius: '50%',
    border: '2px solid rgba(0, 0,0, .0)',
    '&.selected': {
      border: '2px solid rgba(0, 0,0, .25)',
      '& *': {
        border: 'none',
      },
    },
  },
  colorBtn: {
    borderRadius: '50%',
    cursor: 'pointer',
    width: 33,
    height: 33,
  },
}));

export const ColorSelector = ({ control, name }) => {
  const classes = useStyles();
  const colors = ['#123438', '#FF6E00', '#FFC72A', '#CDB58C', '#4DAA62', '#C34747'];
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {colors.map(color => (
              <div key={color} className={clsx(classes.colorContainer, value === color && 'selected')}>
                <div className={classes.colorBtn} style={{ backgroundColor: color }} onClick={() => onChange(color)} />
              </div>
            ))}
          </div>
        );
      }}
    />
  );
};
